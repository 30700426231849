import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/mise.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

// import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="footer" id="contactus">
      <Container>
        <Row className="align-items-center footer-row">
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/company/minervaa-group/about/">
                <img src={navIcon1} alt="Icon" />
              </a>
              <a href="https://www.facebook.com/misexam">
                <img src={navIcon2} alt="Icon" />
              </a>
            </div>
            <p className="footer-contact">info@misexam.com</p>
            <p className="footer-contact">+91 94740 43664</p>

            {/* <Link to="/samplepapers">Sample Papers</Link> */}
            <p>Copyright 2023. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
