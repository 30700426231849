import pic1 from "../assets/img/mise-gallery/1.jpg";
import pic2 from "../assets/img/mise-gallery/2.jpg";
import pic3 from "../assets/img/mise-gallery/3.jpg";
import pic4 from "../assets/img/mise-gallery/4.jpg";
import pic5 from "../assets/img/mise-gallery/5.jpg";
import pic6 from "../assets/img/mise-gallery/6.jpg";
import pic7 from "../assets/img/mise-gallery/7.jpg";
import pic8 from "../assets/img/mise-gallery/8.jpg";
import pic9 from "../assets/img/mise-gallery/9.jpg";
import pic10 from "../assets/img/mise-gallery/10.jpg";
import pic11 from "../assets/img/mise-gallery/11.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="gallery">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Gallery</h2>
              <p>
                Explore the MISE Gallery: A Visual Showcase of Academic
                Excellence and Inspiring Achievements
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <img src={pic3} alt="Image" className="carouselImage" />
                </div>
                <div className="item">
                  <img src={pic4} alt="Image" className="carouselImage"/>
                </div>
                <div className="item">
                  <img src={pic5} alt="Image" className="carouselImage"/>
                </div>
                <div className="item">
                  <img src={pic1} alt="Image" className="carouselImage"/>
                </div>
                <div className="item">
                  <img src={pic2} alt="Image" className="carouselImage"/>
                </div>
                <div className="item">
                  <img src={pic6} alt="Image" className="carouselImage"/>
                </div>
                <div className="item">
                  <img src={pic7} alt="Image" className="carouselImage"/>
                </div>
                <div className="item">
                  <img src={pic8} alt="Image" className="carouselImage"/>
                </div>
                <div className="item">
                  <img src={pic9} alt="Image" className="carouselImage"/>
                </div>
                <div className="item">
                  <img src={pic10} alt="Image" className="carouselImage"/>
                </div>
                <div className="item">
                  <img src={pic11} alt="Image" className="carouselImage"/>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
};
