import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Navbar, Nav } from "react-bootstrap";
import headerImg from "../assets/img/banner2-removebg.png";
import { ArrowRightCircle } from "react-bootstrap-icons";
import "animate.css";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const [activeLink, setActiveLink] = useState("home");
  const period = 2000;

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <span className="tagline">Welcome to MISE</span>
            <h1>Minervaa International Scholarship Examinations</h1>
            <p>
              Discover Minervaa International Scholarship Examinations (MISE),
              empowering students since 13+ years. Recognizing academic
              brilliance, MISE is the premier scholarship exam for students in
              India & Nepal. Join bright minds, compete, and unlock your
              potential. Shape your future with MISE's rigorous tests and
              comprehensive curriculum. Inspiring young learners worldwide, MISE
              is the pathway to success.
            </p>
            <Nav.Link
              href="#aboutus"
              className={
                activeLink === "aboutus" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("aboutus")}
            >
              About Us <ArrowRightCircle size={25} />
            </Nav.Link>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <img src={headerImg} alt="Header Img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
