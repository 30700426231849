import { useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";


import Home from "./components/Home";

function App() {
  useEffect(() => {
    document.title = "MISE | Best Scholarship Exam in India";
  }, []);
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
