import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";

export const Projects = () => {
  return (
    <section className="project" id="aboutus">
      <Container>
        <Row>
          <Col size={12}>
            <h2>About Us</h2>
            <p>
              MISE (MINERVAA International Scholarship Examination) is a
              renowned platform empowering students through scholarship exams
              and School Level Olympiads. With a wide range of academic fields,
              inclusive language options, and comprehensive evaluation, MISE
              offers opportunities for academic excellence, growth, and
              recognition. Join MISE and unlock your potential for extraordinary
              achievements.
            </p>
            <Tab.Container id="projects-tabs" defaultActiveKey="first">
              <Nav
                variant="pills"
                className="nav-pills mb-5 justify-content-center align-items-center"
                id="pills-tab"
              >
                <Nav.Item>
                  <Nav.Link eventKey="first">Who We Are</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Why MISE?</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">What We Do</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content id="slideInUp">
                <Tab.Pane eventKey="first">
                  <Row className="whoweare">
                    <p>
                      Established in 2009, MINERVAA is dedicated to delivering
                      top-notch education to ambitious students. Our goal is to
                      set a new standard in the academic realm, imparting
                      comprehensive knowledge while actively contributing to the
                      growth and well-being of charitable trusts and
                      organizations. We firmly believe in our vision of making
                      education accessible to every individual in the country.
                      MINERVAA has spearheaded numerous contemporary programs in
                      the education sector, nurturing young talents and
                      preparing them for the future. We are committed to
                      fostering skillful societal progress and paving the path
                      to vocational success in education. With unwavering ideals
                      and a dedication to excellence, MINERVAA distinguishes
                      itself as an epitome of success on a global stage.
                    </p>
                    <p>
                      Our organization is proud to provide the perfect balance
                      of international education standards to our students. We
                      stand apart from the competition, offering a distinguished
                      platform for academic achievement. Furthermore, MINERVAA
                      aims to assist educational agencies in developing and
                      implementing effective assessment techniques that enhance
                      the overall quality of education. At MINERVAA, we are
                      passionate about empowering students and shaping a
                      brighter future through education. Join us on our mission
                      to elevate the academic landscape and unlock your
                      potential for success.
                    </p>
                  </Row>
                </Tab.Pane>
                <Tab.Pane className="whymise" eventKey="second">
                  <p>
                    MISE (MINERVAA International Scholarship Examination) is a
                    prestigious scholarship examination designed for students
                    from KG to Class XII. This scientifically standardized
                    assessment, available both online and offline, offers
                    students the opportunity to excel in various academic fields
                    and shape a better future. Conducted annually, MISE invites
                    participation from schools at the national and international
                    level, enabling students to compete with their peers in
                    subjects such as English, Arts and Painting, Abacus,
                    Mathematics, and Computer Skills, among others. We are proud
                    to announce that MISE is now also available in Indian
                    languages like Bengali, ensuring inclusivity and
                    accessibility for students.
                  </p>
                  <p>
                    By participating in MISE, students not only have the chance
                    to compete in School Level Olympiads but also gain access to
                    a wide range of awards and prizes. This enriches their
                    skills and talents while fostering a spirit of healthy
                    competition. Through this scholarship examination, students
                    are empowered to lead better lives and unlock their full
                    potential. It is a unique examination that actively involves
                    teachers, parents, and students in the assessment process,
                    ensuring a comprehensive evaluation of academic excellence.
                    Join MISE today and embark on a journey of academic growth,
                    recognition, and exciting opportunities that will shape your
                    future.
                  </p>
                </Tab.Pane>
                <Tab.Pane className="whatwedo" eventKey="third">
                  <p>
                    At MISE (MINERVAA International Scholarship Examination),
                    our primary focus is on empowering students and providing
                    them with a platform to showcase their academic excellence.
                    We achieve this through various initiatives and programs.
                    Our flagship offering is the annual scholarship examination,
                    open to students from KG to Class XII. This examination
                    spans a wide range of academic fields, including English,
                    Arts and Painting, Abacus, Mathematics, Computer Skills, and
                    more. It attracts participation from schools at the national
                    and international level, allowing students to demonstrate
                    their knowledge, compete with their peers, and excel in
                    their chosen subjects. In our commitment to inclusivity,
                    MISE is now offered in multiple Indian languages, such as
                    Bengali, to ensure accessibility and equal opportunities for
                    students from diverse linguistic backgrounds.
                  </p>
                  <p>
                    As students progress through MISE, they gain access to
                    School Level Olympiads, where they can further showcase
                    their talents and skills. These Olympiads provide an
                    enriching experience, fostering healthy competition and
                    personal growth. Participants have the chance to earn a
                    multitude of awards and prizes, nurturing their confidence
                    and driving their pursuit of academic excellence. At MISE,
                    we believe in a comprehensive evaluation process that
                    involves students, teachers, and parents. This collaborative
                    approach ensures a holistic understanding of each
                    candidate's academic abilities and accomplishments. By
                    engaging all stakeholders, we create an environment that
                    supports students' growth and development, enabling them to
                    unlock their full potential. Join MISE today and become part
                    of our mission to empower students, foster academic growth,
                    and create a platform for extraordinary achievements.
                    Together, we can inspire a generation of bright minds to
                    reach new heights and shape a better future.
                  </p>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
