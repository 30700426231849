import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import prizedetails from "../assets/img/prizedetails.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";

export const Awards = () => {
  return (
    <section className="project" id="awards">
      <Container>
        <Row>
          <Col size={12}>
            <h2>Awards</h2>
            <p>Prizes & Scholarships with respect to marks criteria</p>
            <img className="prizedetailsimg" src={prizedetails} alt="" />
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
