import React from "react";

import { useEffect } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { NavBar } from "./NavBar";
import { Banner } from "./Banner";
import { Skills } from "./Skills";
import { Projects } from "./Projects";
import { ExaminationDetails } from "./ExaminationDetails";
import { Awards } from "./Awards";
import { Footer } from "./Footer";

const Home = () => {
  return (
    <>
      <NavBar />
      <Banner />
      <Projects />
      <Skills />
      <ExaminationDetails />
      <Awards />
      <Footer />
    </>
  );
};

export default Home;
