import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import nurseryLkgUkg from "../assets/img/nurserylkgukg.png";
import iv from "../assets/img/iv.png";
import vix from "../assets/img/vix.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";

export const ExaminationDetails = () => {
  return (
    <section className="project" id="examinationdetails">
      <Container>
        <Row>
          <Col size={12}>
            <h2>Examination Details</h2>
            <p>No. of Questions, Marks & Duration</p>
            <Tab.Container id="projects-tabs" defaultActiveKey="first">
              <Nav
                variant="pills"
                className="nav-pills mb-5 justify-content-center align-items-center"
                id="pills-tab"
              >
                <Nav.Item>
                  <Nav.Link eventKey="first">Nursery, LKG, UKG</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">I-V</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">VI-X</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content id="slideInUp">
                <Tab.Pane eventKey="first">
                  <Row className="nurserylkgukg">
                    <img src={nurseryLkgUkg} alt="" />
                  </Row>
                </Tab.Pane>
                <Tab.Pane className="iv" eventKey="second">
                  <img src={iv} alt="" />
                </Tab.Pane>
                <Tab.Pane className="vix" eventKey="third">
                  <img src={vix} alt="" />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
