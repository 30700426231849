import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../assets/img/mise.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import { HashLink } from "react-router-hash-link";
import { BrowserRouter as Router } from "react-router-dom";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                href="#aboutus"
                className={
                  activeLink === "aboutus"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("aboutus")}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                href="#examinationdetails"
                className={
                  activeLink === "examinationdetails"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("examinationdetails")}
              >
                Examination Details
              </Nav.Link>
              <Nav.Link
                href="#awards"
                className={
                  activeLink === "awards" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("awards")}
              >
                Awards
              </Nav.Link>
              <Nav.Link
                href="#gallery"
                className={
                  activeLink === "gallery"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("gallery")}
              >
                Gallery
              </Nav.Link>
              <Nav.Link
                href="https://drive.google.com/drive/folders/1aPH9T3_fZsk8S5vRvz2Jkqyokchj-08J?usp=sharing"
                className={
                  activeLink === "samplepapers"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("samplepapers")}
              >
                Sample Papers
              </Nav.Link>
            </Nav>
            <span className="navbar-text">
              <HashLink to="#contactus">
                <button className="vvd">
                  <span>Contact Us</span>
                </button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  );
};
